<template>
  <div
    v-if="brands && brands.length"
    ref="logoslider"
    class="logo-slider"
    :class="{ 'logo-slider--nav': nav }"
  >
    <div class="swiper-wrapper">
      <div
        v-for="(logo, index) in brands"
        :key="index"
        class="logo-slider__logo swiper-slide"
      >
        <!-- {{ brand.logo }} -->
        <img :src="logo.logo.url" alt="" loading="lazy" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Swiper from "swiper/bundle";

const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  nav: {
    type: Boolean,
    default: false,
  },
});

const logoAutomaticSlides = ref(null);
const logoslider = ref(null);

const brands = computed(() => {
  return store.getBrands;
});

const initSwiper = () => {
  logoAutomaticSlides.value = new Swiper(logoslider.value, {
    loop: true,
    slidesPerView: "auto",
    speed: 1000,
    direction: "vertical",
    // cssMode: true,
    autoplay: {
      delay: 1000,
    },
  });
};

onMounted(() => {
  initSwiper();
});
</script>

<style lang="scss">
.logo-slider {
  overflow: hidden;
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  background: $color-white;
  border-radius: 50%;
  margin: 0 $spacing-base;

  @media (min-width: $md) {
    width: 100px;
    height: 100px;
    flex: 0 0 100px;
  }

  @media (min-width: $lg) {
  }

  &--nav {
    width: 75px;
    height: 75px;
    flex: 0 0 75px;

    @media (min-width: $md) {
      width: 75px;
      height: 75px;
      flex: 0 0 75px;
    }

    @media (min-width: $xxl) {
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
    }
  }

  .swiper-wrapper {
    // transition-timing-function: linear !important;
  }
}

.logo-slider__logo {
  width: 100%;
  padding: $spacing-base;
  opacity: 0;
  transition: all 0.1s ease-in-out;
  transition-delay: 400ms;

  &.swiper-slide-active {
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
