<template>
  <div
    class="strapi-anchor"
    :data-anchor-name="data.Name"
    :data-anchor-id="prettyPath(data.Name)"
    :id="prettyPath(data.Name)"
  ></div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

onMounted(() => {});
</script>

<style lang="scss"></style>
