<template>
  <div class="strapi-section">
    <div class="container">
      <div class="strapi-frame">
        <iframe
          class="strapi-frame__frame"
          :src="data.Link"
          :width="data.Width ? data.Width : '100%'"
          :height="data.Height ? data.Height : '100%'"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  data: {
    type: Object,
    default: () => {},
  },
});

onMounted(() => {});
</script>

<style lang="scss">
.strapi-frame {
  width: 100%;
}
.strapi-frame__frame {
  margin: 0 auto;
  max-width: 100%;
}
</style>
