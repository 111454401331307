<template>
  <div
    v-if="needArrows"
    class="arrow-nav"
    :class="{ 'arrow-nav--pull-top': pullTop }"
  >
    <div class="arrow-nav__inner">
      <div class="arrow-nav__icon" @click="move('backward')">
        <div class="icon-arrow-left"></div>
      </div>
      <div class="arrow-nav__icon" @click="move('forward')">
        <div class="icon-arrow-right"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const is_touch_enabled = computed(() => {
  return (
    "ontouchstart" in window ||
    navigator?.maxTouchPoints > 0 ||
    navigator?.msMaxTouchPoints > 0
  );
});

const props = defineProps({
  targetId: {
    default: null,
    type: String,
  },
  pullTop: {
    default: false,
    type: Boolean,
  },
});

const needArrows = ref(false);
const targetElement = ref(null);

onMounted(() => {
  targetElement.value = document.querySelector(props.targetId);

  if (
    targetElement.value &&
    targetElement.value.scrollWidth > targetElement.value.clientWidth &&
    !is_touch_enabled.value
  ) {
    needArrows.value = true;
  }
});

const move = (direction) => {
  if (direction === "backward") {
    targetElement.value.scrollLeft -= targetElement.value.clientWidth / 2;
  } else if (direction === "forward") {
    targetElement.value.scrollLeft += targetElement.value.clientWidth / 2;
  }
};
</script>

<style lang="scss">
.arrow-nav {
  margin-top: $spacing-sm;
  margin-bottom: 0;

  &--pull-top {
    margin-top: -1rem;
    margin-bottom: $spacing-sm;
  }
}

.arrow-nav__icon {
  cursor: pointer;
}

.arrow-nav__inner {
  display: flex;
  justify-content: flex-end;
}

.arrow-nav__icon {
  margin-left: 0.5rem;
}
</style>
