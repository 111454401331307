<template>
  <div>
    <!-- Currently not in use. Uye this Template when working on animal profile. -->
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

onMounted(() => {});
</script>

<style lang="scss"></style>
