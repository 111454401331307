<template>
  <div v-if="rank" class="loyalty-ranks-item" :class="{ active: active }">
    <div class="loyalty-ranks-icon" :class="{ active: active }">
      <img :src="getRankAsset(rank)" alt="" />
    </div>
    <div class="loyalty-ranks-name" :class="{ active: active }">
      <span v-if="currentPoints">
        {{ currentPoints }}
      </span>
      <span v-else-if="active"> Dein Rang </span>
      <span v-else>
        {{ rank.name }}
      </span>
    </div>
    <div v-if="subline" class="loyalty-ranks-subline">
      {{ subline }}
    </div>
  </div>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  rank: {
    type: Object,
    default: () => {},
  },
  active: {
    type: Boolean,
    default: false,
  },
  currentPoints: {
    type: Number,
    default: null,
  },
  subline: {
    type: String,
    default: null,
  },
});

const getRankAsset = (rank) => {
  const currentTranslation = rank.translations.find(
    (asset) => asset.assetId === rank.translated.assetId
  );
  if (
    currentTranslation &&
    currentTranslation.asset &&
    currentTranslation.asset.url
  ) {
    return currentTranslation.asset.url;
  } else {
    return null;
  }
};
</script>

<style lang="scss" scoped>
.loyalty-ranks-item {
  margin: $spacing-base;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  width: 11rem;
}

.loyalty-ranks-icon {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  background: $color-white;
  position: relative;
  //   margin-bottom: $spacing-xs;

  &.active {
    background: $color-green;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
  }
}

.loyalty-ranks-name {
  font-size: $text-sm;
  font-weight: $font-bold;
  text-align: center;
  padding: 2px $spacing-base;
  border: 2px solid $color-gray;
  border-radius: $radius-large;
  background: $color-white;
  transform: translateY(-5px);
}

.loyalty-ranks-subline {
  color: $color-dark-gray;
  font-size: $text-sm;
  text-align: center;
}
</style>
